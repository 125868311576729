import React, { useState, useEffect } from 'react';


import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import './LoginPage.css';

import axios from 'axios';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  useEffect(() => {
    setIsButtonEnabled(username !== '' && password !== '');
  }, [username, password]);

  const handleLogin = (event) => {
    event.preventDefault();
    console.log('Username:', username);
    console.log('Password:', password);
    
    // ECC: calling OMM for authentication
    submitRequest(username, password);
    
    console.log('Called submitRequest() to Login.');
  };

  const handleGoogleLoginSuccess = (credentialResponse) => {
    console.log('Google Login Success:', credentialResponse);
    alert('Google Logged in!');
    // Here you could send the credentialResponse to your backend for further authentication processing
  };

  const handleGoogleLoginFailure = () => {
    alert('Google Login Failed');
  };

  return (
    <GoogleOAuthProvider clientId="170426737262-ntd1lh86qqp00i7lhd2voo4rkct0qc6k.apps.googleusercontent.com">
      <div className="App">
        <div className="navbar">    
          <h1>ResuFit AI</h1>
        </div>
        <div className="login_container">
          <h3>Login</h3>
          <form onSubmit={handleLogin}>
            <div style={{ marginBottom: '1rem' }}>
              <label>Username</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                style={{ width: '100%', padding: '0.5rem' }}
                required
              />
            </div>
            <div style={{ marginBottom: '1rem' }}>
              <label>Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{ width: '100%', padding: '0.5rem' }}
                required
              />
            </div>
            <button
              className={`login_button ${isButtonEnabled ? 'show' : ''}`}
              disabled={!isButtonEnabled}
            >
              Login
            </button>
          </form>
          <div style={{ marginTop: '1rem' }}>
            <GoogleLogin
              onSuccess={handleGoogleLoginSuccess}
              onError={handleGoogleLoginFailure}
            />
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

  
// ECC: function to submit HTTPrequest to JSP
function submitRequest(uname, passwd) {
  const params = new URLSearchParams();
  params.append('Uid', uname);
  params.append('Password', passwd);

  axios.post('/UP/checklogin_up.jsp', params)
      .then(response => {
          // Handle success
          if (response.data.status === 'success') {
              console.log('Login successful:', response.data);
              // You can add additional logic here, such as redirecting the user
          } else {
              console.error('Login failed:', response.data.message || 'Unknown error');
              alert(`Login failed: ${response.data.message || 'Unknown error'}`);
          }
      })
      .catch(error => {
          // Handle error
          if (error.response) {
              // Server responded with a status code outside the range of 2xx
              console.error('Error response:', error.response.data);
              alert(`Error: ${error.response.data.message || 'Server error'}`);
          } else if (error.request) {
              // Request was made, but no response received
              console.error('No response received:', error.request);
              alert('No response from server. Please try again later.');
          } else {
              // Something else happened while setting up the request
              console.error('Error setting up request:', error.message);
              alert(`Error setting up request: ${error.message}`);
          }
      });
}


export default LoginPage;
