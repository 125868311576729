import React, { useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import './JobDesc.css';

function JobDescription() {
    const [input, setInput] = useState('');
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [submit, setSubmit] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const { fileName } = location.state || {};

    const updateStatus = (message) => {
      setStatus(message);
      console.log(message);
    };
    
    const handleSubmit = async (event) => {
      event.preventDefault();
      setLoading(true);
      let jobDescription = input; 
      updateStatus('');
      //await new Promise(resolve => setTimeout(resolve, 5000)); // Replaces wait(5000)
      updateStatus('Creating Resume');
      try {
        const api_response = await axios.post('/api/resume_editor', {
          fileName,
          jobDescription,
        });
        
        if (api_response.data.success) {
          updateStatus('Check out the Resume');
          //await new Promise(resolve => setTimeout(resolve, 3000));
          navigate('/result', { state: { fileName } });
        } else {
          updateStatus('Something went wrong, try again');
        }
      } catch (error) {
        updateStatus('Error creating resume');
        console.error(error);
      } finally {
        setLoading(false); // Always called at the end, even on error
      }
    };
    
    return (    
      <div className="App">
        <div className="navbar">
          <h1>ResuFit AI</h1>
        </div>
        <div className="job-description-container">
          <h3>Submit Job Description</h3>
          <form onSubmit={handleSubmit} className="job-description-form">
            <textarea
              className="textarea"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Click and paste job description from the job posting"
            ></textarea>
            <button
              className={`analyze-button ${input ? 'show' : ''}`}
              disabled={!input}
            >
              Analyze
            </button>
          </form>
          <div className="status-container">
            <p>{status}</p>
          </div>
          {loading && (
              <div class="loading-container">
                <div class="loading-square"></div>
                <div class="loading-square"></div>
                <div class="loading-square"></div>
                <div class="loading-square"></div>
              </div>
            )}
        </div>
      </div>
    );

  }
  
  export default JobDescription;