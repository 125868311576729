import React, { useEffect, useRef } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import './ResultPage.css';

function ResultPage() {
    const iframeRef = useRef(null);
    const location = useLocation();
    const { fileName } = location.state || {};

    useEffect(() => {
        axios.get(`/api/get_resume/${fileName}`, { responseType: 'text' })
            .then(response => {
                if (iframeRef.current) {
                    const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
                    iframeDocument.body.innerHTML = response.data;
                    iframeDocument.body.contentEditable = "true"; // Make the iframe body editable
                }
            })
            .catch(error => {
                console.error('Error fetching the HTML file', error);
            });
    }, [fileName]);

    const handleButtonClick = () => {
    const htmlData = iframeRef.current.contentDocument.body.innerHTML;
    
    axios.post(`/api/download_resume/${fileName}`, null, {
        responseType: 'blob',
    })
    .then(response => {
        // Create a new Blob object using the response data of the PDF
        const blob = new Blob([response.data], { type: 'application/pdf' });
    
        // Create a download URL for the Blob object
        const downloadUrl = window.URL.createObjectURL(blob);
    
        // Create a link element and set its href to the download URL
        const fileLink = document.createElement('a');
        fileLink.href = downloadUrl;
    
        // Set the download attribute with the desired file name
        fileLink.setAttribute('download', `${fileName}.pdf`);
    
        // Append the link to the document and trigger a click to initiate download
        document.body.appendChild(fileLink);
        fileLink.click();
    
        // Clean up by removing the link and revoking the Blob URL
        document.body.removeChild(fileLink);
        window.URL.revokeObjectURL(downloadUrl);
    })
    .catch(error => {
        console.error('Error downloading the PDF file', error);
    });  
};
    return (
        <div className="editor-container">
            <button onClick={handleButtonClick} className="download-button">
                Download PDF
            </button>
            <iframe
                ref={iframeRef}
                title="Editable Resume"
                className="iframe-content"
                style={{ width: '100%', height: '90vh' }}
            />
        </div>
    );
}
export default ResultPage;
