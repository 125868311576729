import React, { useState } from 'react';
import './TestPage.css'; // Import the CSS for styling

function TestPage() {
  const [isLoading, setIsLoading] = useState(false);

  const startLoading = () => {
    setIsLoading(true);
    
    // Simulate a delay, such as loading content (e.g., 6 seconds)
    setTimeout(() => {
      setIsLoading(false);
    }, 6000); // Loading bar visible for 6 seconds
  };

  return (
    <div id="app">
      <h1>Loading Bar Test</h1>

      {/* Conditional display of the loading bar */}
      {isLoading && (
        <div class="loading-container">
          <div class="loading-square"></div>
          <div class="loading-square"></div>
          <div class="loading-square"></div>
          <div class="loading-square"></div>
      </div>
      )}

      <button onClick={startLoading} id="startLoading">
        Start Loading
      </button>
    </div>
  );
}

export default TestPage;
